$(".inline").modaal({});

$('.js-modal-close').on('click', function () {
  // モーダルを閉じる
  $('.inline').modaal('close');
})

$(document).ready(function() {
  $('a[href^="#anchor"]').on('click', function(event) {
    event.preventDefault();
    let target = $(this.hash);
    let targetOffset = target.offset().top;
    $('html, body').animate({ scrollTop: targetOffset }, 1000);
  });
});